/* App.css */
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f8ff;
  overflow: hidden;
  position: relative;
}


body {
  overflow: hidden; /* Pour éviter le défilement pendant l'animation */
}

@keyframes glitch {
  0% { transform: translate(0, 0); opacity: 1; }
  20% { transform: translate(-5px, 5px); opacity: 0.8; }
  40% { transform: translate(5px, -5px); opacity: 0.6; }
  60% { transform: translate(-5px, -5px); opacity: 0.4; }
  80% { transform: translate(5px, 5px); opacity: 0.2; }
  100% { transform: translate(0, 0); opacity: 1; }
}

.glitch {
  animation: glitch 0.3s infinite;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: lime;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}


.snowflake {
  position: absolute;
  top: -10px;
  color: #ffffff;
  font-size: 1.5em;
  opacity: 0.8;
  animation: fall linear infinite;
}

.snowflake:nth-child(5n) {
  color: #b3e5fc; /* Variation de couleur pour les flocons */
}

.leaf {
  position: absolute;
  top: -10px;
  color: #00a000;
  font-size: 1.5em;
  opacity: 0.9;
  animation: fall linear infinite;
}

@keyframes fall {
  0% { transform: translateY(-10px) rotate(0deg); opacity: 1; }
  100% { transform: translateY(100vh) rotate(360deg); opacity: 0; }
}

.App {
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  z-index: 10; /* Assure que l'interface est au-dessus des flocons */
}

h1 {
  color: #d32f2f;
  font-size: 1.5em;
  margin-bottom: 20px;
}
.mystery-container {
  text-align: justify;
  margin: 10px; /* Moins de marge pour les petits écrans */
  padding: 15px; /* Padding réduit */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  color: #fff;
  font-size: 0.9rem; /* Texte légèrement plus petit */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.mystery-text {
  font-size: 1rem; /* Plus petit pour les portables */
  line-height: 1.4; /* Espacement réduit pour économiser de l’espace */
  margin-bottom: 15px;
}

.mystery-link {
  font-size: 0.85rem; /* Taille encore plus petite pour le lien */
  word-break: break-word; /* Coupe le lien si nécessaire */
  text-align: center;
  color: #a5d6a7;
}

/* Media query pour écrans de moins de 600px */
@media (max-width: 600px) {
  .mystery-container {
    margin: 5px; /* Réduction supplémentaire des marges */
    padding: 10px;
  }

  .mystery-text {
    font-size: 0.9rem;
    line-height: 1.3;
  }

  .mystery-link {
    font-size: 0.8rem;
  }
}

form {
  display: flex;
  flex-direction: column;
}

input[type="text"] {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}

button {
  background-color: #d32f2f;
  color: white;
  padding: 10px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background 0.3s;
}

button:hover {
  background-color: #b71c1c;
}
